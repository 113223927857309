import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './contact-form3.css'

const ContactForm3 = (props) => {
  return (
    <div id="contactF" className="contact-form3-contact9 thq-section-padding">
      <div className="thq-flex-row thq-section-max-width contact-form3-max-width">
        <div className="contact-form3-container">
          <video
            src="/17.mp4"
            loop="true"
            muted="true"
            autoPlay="true"
            playsInline="true"
            className="contact-form3-video"
          ></video>
        </div>
        <div className="contact-form3-content thq-flex-column">
          <div className="contact-form3-section-title thq-card">
            <div className="contact-form3-content1">
              <h2 className="contact-form3-text">{props.heading1}</h2>
              <span className="contact-form3-text1">{props.content1}</span>
            </div>
          </div>
          <form className="thq-card">
            <div className="contact-form3-input">
              <label
                htmlFor="contact-form-3-name"
                className="contact-form3-text2"
              >
                Nom et Prénom
              </label>
              <input
                type="text"
                id="contact-form-3-name"
                required="true"
                placeholder="Nom et prénom"
                className="contact-form3-text-input thq-input"
              />
            </div>
            <div className="contact-form3-input1">
              <label
                htmlFor="contact-form-3-email"
                className="contact-form3-text3"
              >
                Email
              </label>
              <input
                type="email"
                id="contact-form-3-email"
                required="true"
                placeholder="Email"
                className="contact-form3-text-input1 thq-input"
              />
            </div>
            <div className="contact-form3-container1">
              <label
                htmlFor="contact-form-3-message"
                className="contact-form3-text4"
              >
                Message
              </label>
              <textarea
                id="contact-form-3-message"
                rows="3"
                placeholder=" Message"
                className="thq-input"
              ></textarea>
            </div>
            <div className="contact-form3-checkbox"></div>
            <button type="submit" className="contact-form3-button button">
              <span className="thq-body-small">{props.action}</span>
            </button>
          </form>
          <div>
            <div className="contact-form3-container3">
              <Script
                html={`<script>
    function sendWebhook() 
    {
        const name = document.getElementById('contact-form-3-name').value;
        const email = document.getElementById('contact-form-3-email').value;
        const message = document.getElementById('contact-form-3-message').value;

        const payload = {
            name: name,
            email: email,
            message: message
        };

        fetch('https://n8n.rec-it.net/webhook-test/site-webhook', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        .then(response => {
            if (!response.ok) {
            throw new Error('Failed to send webhook');
            }
            console.log('Webhook sent successfully');
        })
        .catch(error => {
            console.error('Error sending webhook:', error);
        });
    }

    document.querySelector('.contact-form3-button').addEventListener('click', function(event) {
      event.preventDefault();
      sendWebhook();
    });
  </script>`}
              ></Script>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ContactForm3.defaultProps = {
  content1:
    'Ensemble, tout est possible. Vous pouvez nous contacter en utilisant ce formulaire ou en envoyant un email à contact@rec-it.net.',
  action: 'Envoyer',
  heading1: 'CONTACTEZ-NOUS',
  imageAlt: 'Image1',
  imageSrc:
    'https://images.unsplash.com/photo-1712945783006-10210e53ff46?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNDkzMzI5OXw&ixlib=rb-4.0.3&q=80&w=1080',
  content2: 'Get in touch with us',
}

ContactForm3.propTypes = {
  content1: PropTypes.string,
  action: PropTypes.string,
  heading1: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  content2: PropTypes.string,
}

export default ContactForm3
