import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonial13.css'

const Testimonial13 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="testimonial13-max-width thq-section-max-width">
        <div className="testimonial13-section-title">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="testimonial13-text48 thq-heading-2">
                  Témoignages de Nos Clients
                </h2>
              </Fragment>
            )}
          </h2>
          <p>
            {props.content1 ?? (
              <Fragment>
                <p className="testimonial13-text28 thq-body-large">
                  Découvrez ce que nos clients pensent de notre collaboration et
                  des solutions que nous leur avons apportées.
                </p>
              </Fragment>
            )}
          </p>
        </div>
        <div className="testimonial13-container thq-flex-row">
          <div className="testimonial13-content1 thq-flex-column">
            <div className="testimonial13-stars1">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
            </div>
            <p>
              {props.review1 ?? (
                <Fragment>
                  <p className="testimonial13-text29 thq-body-large">
                    <span>
                      Nous souhaitons par la présente recommander
                      chaleureusement les services de Reflection Engineering
                      Consultancy (R.E.C.) avec qui nous avons collaboré sur un
                      projet d’analyse des 
                    </span>
                    <span>
                      cahiers des charges dans le secteur du génie civil. Grâce
                      à l&apos;utilisation de technologies avancées 
                    </span>
                    <span>
                      telles que la Full Stack Generative IA et les solutions
                      Low Code, R.E.C. a su apporter une valeur 
                    </span>
                    <span>ajoutée significative à notre projet.</span>
                    <br></br>
                    <br></br>
                    <span>
                      Nous avons été extrêmement satisfaits de la qualité du
                      travail fourni. Grâce à son expertise en 
                    </span>
                    <span>
                      intelligence artificielle et sa maîtrise parfaite des
                      outils low-code, R.E.C. nous a permis d&apos;obtenir 
                    </span>
                    <span>
                      une solution qui répondait parfaitement à nos besoins en
                      un temps record et avec un budget 
                    </span>
                    <span>
                      optimisé. Leur capacité à proposer des solutions
                      innovantes et adaptées a été particulièrement 
                    </span>
                    <span>appréciée.</span>
                    <br></br>
                    <br></br>
                    <span>
                      Nous recommandons vivement Reflection Engineering
                      Consultancy (R.E.C.) à toute entreprise 
                    </span>
                    <span>
                      souhaitant bénéficier de services de haute qualité dans le
                      domaine des technologies avancées, 
                    </span>
                    <span>
                      appliquées non seulement au génie civil, mais également à
                      d&apos;autres secteurs. Leur 
                    </span>
                    <span>
                      professionnalisme et leur expertise font d&apos;eux un
                      partenaire de choix pour mener à bien des 
                    </span>
                    <span>projets complexes et innovants.</span>
                  </p>
                </Fragment>
              )}
            </p>
            <div className="testimonial13-avatar1">
              <img
                alt={props.author1Alt}
                src={props.author1Src}
                className="testimonial13-avatar-image1 thq-img-round thq-img-ratio-1-1"
              />
              <div className="testimonial13-avatar-content1">
                <span>
                  {props.author1Name ?? (
                    <Fragment>
                      <span className="testimonial13-text51 thq-body-small">
                        Maxime Toury
                      </span>
                    </Fragment>
                  )}
                </span>
                <span>
                  {props.author1Position ?? (
                    <Fragment>
                      <span className="testimonial13-text50 thq-body-small">
                        Dirigeant &amp; Fondateur chez QuantiBat
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <img
                alt={props.company1LogoAlt}
                src={props.company1LogoSrc}
                className="testimonial13-logo1"
              />
            </div>
          </div>
          <div className="testimonial13-content2 thq-flex-column">
            <div className="testimonial13-stars2">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 736l-264 160 70-300-232-202 306-26 120-282 120 282 306 26-232 202 70 300z"></path>
              </svg>
            </div>
            <p>
              {props.review2 ?? (
                <Fragment>
                  <p className="testimonial13-text16 thq-body-large">
                    <span>
                      Nous avons sollicité REC IT pour développer une solution
                      d&apos;intelligence artificielle adaptée à nos besoins, et
                      nous avons été pleinement satisfaits par la prestation
                      fournie. En seulement quelques semaines, l&apos;équipe a
                      livré une solution qui correspondait exactement à nos
                      attentes.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      La maîtrise technique de Yasmine et de son équipe a été un
                      facteur clé pour transformer nos exigences en une solution
                      fonctionnelle et efficace. Leur approche méthodique et
                      leur capacité à s&apos;adapter aux défis rencontrés ont
                      grandement contribué à la réussite du projet.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      Nous recommandons REC IT pour toute entreprise cherchant
                      une expertise technique solide et une approche orientée
                      résultats.
                    </span>
                    <br></br>
                    <span className="testimonial13-text25">
                      Accédez au projet réalisé par REC :
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="testimonial13-text26">
                      https://heybildbot.com
                    </span>
                    <br></br>
                  </p>
                </Fragment>
              )}
            </p>
            <div className="testimonial13-avatar2">
              <img
                alt={props.author2Alt}
                src={props.author2Src}
                className="testimonial13-avatar-image2 thq-img-round"
              />
              <div className="testimonial13-avatar-content2">
                <span>
                  {props.author2Name ?? (
                    <Fragment>
                      <span className="testimonial13-text49 thq-body-small">
                        Michel Cance
                      </span>
                    </Fragment>
                  )}
                </span>
                <span>
                  {props.author2Position ?? (
                    <Fragment>
                      <span className="testimonial13-text52 thq-body-small">
                        PropTech/ConTech Innovator: AI Solutions for Real Estate
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <img
                alt={props.company2LogoAlt}
                src={props.company2LogoSrc}
                className="testimonial13-logo2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonial13.defaultProps = {
  review2: undefined,
  content1: undefined,
  review1: undefined,
  author1Alt: 'John Smith - CEO of ABC Inc.',
  company1LogoAlt: 'ABC Inc. Logo',
  heading1: undefined,
  company1LogoSrc: '/quantibatlogo-1500h.png',
  author1Src: '/maxime-200h.jpeg',
  company2LogoAlt: 'XYZ Company Logo',
  author2Name: undefined,
  author1Position: undefined,
  author2Alt: 'Emily Johnson - Marketing Manager at XYZ Company',
  company2LogoSrc: '/bildbot_logo-200h.jpg',
  author1Name: undefined,
  author2Src: '/michelcance-200w.jpeg',
  author2Position: undefined,
}

Testimonial13.propTypes = {
  review2: PropTypes.element,
  content1: PropTypes.element,
  review1: PropTypes.element,
  author1Alt: PropTypes.string,
  company1LogoAlt: PropTypes.string,
  heading1: PropTypes.element,
  company1LogoSrc: PropTypes.string,
  author1Src: PropTypes.string,
  company2LogoAlt: PropTypes.string,
  author2Name: PropTypes.element,
  author1Position: PropTypes.element,
  author2Alt: PropTypes.string,
  company2LogoSrc: PropTypes.string,
  author1Name: PropTypes.element,
  author2Src: PropTypes.string,
  author2Position: PropTypes.element,
}

export default Testimonial13
