import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import IconContainer from '../components/icon-container'
import Testimonial13 from '../components/testimonial13';
import ContactForm3 from '../components/contact-form3'
import Gallery5 from '../components/gallery5'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Ai.rec-it.net</title>
        <meta property="og:title" content="Ai.rec-it.net" />
      </Helmet>
      <section id="HOME" className="home-hero">
        <video
          src="/ai1.mp4"
          loop
          muted
          preload="auto"
          autoPlay
          playsInline
          className="home-video"
        ></video>
        <header data-thq="thq-navbar" className="home-navbar">
          <img
            alt="image"
            src="/untitled%20design%20(1).svg"
            className="home-branding"
          />
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <div className="home-hamburger">
              <img alt="image" src="/hamburger.svg" className="home-icon" />
            </div>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav"
            >
              <div className="home-container01">
                <img
                  alt="image"
                  src="/untitled%20design%20(1).svg"
                  className="home-image"
                />
                <div data-thq="thq-close-menu" className="home-menu-close">
                  <svg viewBox="0 0 1024 1024" className="home-icon01">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="home-nav1"
              >
                <a href="#HOME" className="home-link">
                  Home
                </a>
                <a href="#s1" className="home-link01">
                  Solutions
                </a>
                <a href="#Services" className="home-link02">
                  Services
                </a>
                <a href="#qui sommesnous" className="home-link03">
                  Qui Sommes-Nous ?
                </a>
                <a href="#media" className="home-link04">
                  <span>Medias</span>
                  <br></br>
                </a>
                <a href="#contactF" className="home-link05">
                  <span>Contactez-Nous</span>
                  <br></br>
                </a>
              </nav>
            </div>
            <div className="home-icon-group">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon03">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon05">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon07">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
        <div className="home-hero-content">
          <div className="home-header-container">
            <div className="home-header">
              <h1 className="home-heading">
                <span>IA Solutions</span>
                <br></br>
              </h1>
            </div>
            <p className="home-caption">
              REC propulse votre entreprise vers l&apos;avenir avec une
              technologie d&apos;Intelligence Artificielle de pointe, vous
              permettant de surpasser la concurrence et d&apos;innover sans
              limites !
            </p>
          </div>
          <a
            href="https://tidycal.com/ai-rec/15-minute-meeting"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link06 button"
          >
            <span>
              <span>Réserver une Consultation Gratuite</span>
              <br></br>
            </span>
          </a>
        </div>
      </section>
      <section className="home-note">
        <h2 className="home-caption01">
          Chez REC, nous comprenons les besoins diversifiés en IA des
          entreprises modernes. Que vous recherchiez une solution IA
          personnalisée ou un chatbot intelligent pour une communication client
          efficace, nous avons ce qu&apos;il vous faut.
        </h2>
      </section>
      <section className="home-statistics">
        <div className="home-content">
          <div className="home-stat">
            <h3 className="home-header01">Low-code</h3>
            <span className="home-caption02">
              Développement plus rapide
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </div>
          <div className="home-stat1">
            <h3 className="home-header02">IA</h3>
            <span className="home-caption03">Expérience client améliorée</span>
          </div>
          <div className="home-stat2">
            <h3 className="home-header03">DevOps</h3>
            <span className="home-caption04">
              Collaboration et déploiement efficaces
            </span>
          </div>
        </div>
      </section>
      <section id="Services" className="home-slider">
        <div className="home-header04">
          <h2 className="home-heading01">
            <span>Explorez l&apos;</span>
            <span className="home-text10">Innovation</span>
            <span> avec REC</span>
          </h2>
        </div>
        <div className="home-selector">
          <p className="home-caption05">
            Ne laissez pas les obstacles techniques freiner le potentiel complet
            de l&apos;IA pour votre entreprise. Collaborez avec REC pour ouvrir
            de nouvelles voies vers le succès, que vous recherchiez des
            solutions personnalisées ou des offres de chatbot évolutives.
          </p>
          <div className="home-slide-titles">
            <div className="slide-title">
              <span>AI_Chatbots</span>
            </div>
            <div className="slide-title slide-title-active">
              <span>AI_Application</span>
            </div>
            <div className="slide-title">
              <span>Automation</span>
            </div>
            <div className="slide-title">
              <span>Integration</span>
            </div>
          </div>
        </div>
      </section>
      <section className="home-slides">
        <div className="home-slider1 slider blaze-slider">
          <div className="home-slider-container blaze-container">
            <div className="home-slider-track-container blaze-track-container">
              <div className="home-slider-track blaze-track">
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/purple%20blue%20modern%20geometrical%20%20ai%20art%20instagram%20post%20%20(2)-400h.webp"
                    className="home-image01"
                  />
                  <div className="home-content01">
                    <div className="home-header05">
                      <h3 className="home-heading02">AI Chatbot</h3>
                      <p className="home-caption06">
                        Améliorez la qualité de service et augmentez vos ventes
                        grâce à un chatbot personnalisé pour votre secteur
                        d&apos;activité : e-commerce, service client, marketing
                        ou support
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </p>
                    </div>
                    <div className="home-more">
                      <a href="#s1" className="home-caption07">
                        Read More
                      </a>
                      <svg viewBox="0 0 1024 1024" className="home-icon09">
                        <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/slider-ecosystems-400h.webp"
                    className="home-image02"
                  />
                  <div className="home-content02">
                    <div className="home-header06">
                      <h3 className="home-heading03">AI Application</h3>
                      <p className="home-caption08">
                        Low code simplifie le développement avec moins
                        d&apos;erreurs et une évolutivité adaptable à tous les
                        budgets.
                      </p>
                    </div>
                    <div className="home-more1">
                      <a href="#s1" className="home-caption09">
                        Read More
                      </a>
                      <svg viewBox="0 0 1024 1024" className="home-icon11">
                        <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/purple%20blue%20modern%20geometrical%20%20ai%20art%20instagram%20post%20%20(1)-400h.webp"
                    className="home-image03"
                  />
                  <div className="home-content03">
                    <div className="home-header07">
                      <h3 className="home-heading04">Automatisation</h3>
                      <p className="home-caption10">
                        De l&apos;automatisation du traitement des documents à
                        l&apos;analyse des conversations clients, nos solutions
                        IA pratiques offrent des résultats plus rapides,
                        réduisent les coûts et minimisent les risques.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </p>
                    </div>
                    <div className="home-more2">
                      <a href="#s2" className="home-caption11">
                        Read More
                      </a>
                      <svg viewBox="0 0 1024 1024" className="home-icon13">
                        <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div data-role="current-banner" className="banner home-slide3">
                  <img
                    alt="image"
                    src="/purple%20blue%20modern%20geometrical%20%20ai%20art%20instagram%20post%20%20(3)-400h.webp"
                    className="home-image04"
                  />
                  <div className="home-content04">
                    <div className="home-header08">
                      <h3 className="home-heading05">Intégration</h3>
                      <p className="home-caption12">
                        Notre approche LLMOps et DevOps couvre
                        l&apos;intégralité du cycle de vie des LLMs, du
                        développement au déploiement, favorisant ainsi la
                        croissance et l&apos;innovation
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </p>
                    </div>
                    <div className="home-more3">
                      <a href="#s2" className="home-caption13">
                        Read More
                      </a>
                      <svg viewBox="0 0 1024 1024" className="home-icon15">
                        <path d="M298.667 341.333h323.669l-353.835 353.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l353.835-353.835v323.669c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-426.667c0-5.803-1.152-11.307-3.243-16.341s-5.163-9.728-9.216-13.781c-0.043-0.043-0.043-0.043-0.085-0.085-3.925-3.925-8.619-7.083-13.781-9.216-5.035-2.091-10.539-3.243-16.341-3.243h-426.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-controls">
              <button
                data-role="previous-banner-button"
                className="home-previous blaze-prev button"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon17">
                  <path d="M542.165 780.501l-225.835-225.835h494.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-494.336l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-298.667 298.667c-4.096 4.096-7.168 8.789-9.259 13.824-2.176 5.205-3.243 10.795-3.243 16.341 0 10.923 4.181 21.845 12.501 30.165l298.667 298.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
                </svg>
              </button>
              <button
                data-role="next-banner-button"
                className="home-next blaze-next button"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon19">
                  <path d="M481.835 243.499l225.835 225.835h-494.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h494.336l-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l298.667-298.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-298.667-298.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="home-container02">
          <div>
            <div className="home-container04">
              <Script
                html={`<script>
  document.addEventListener("DOMContentLoaded", function () {
    const slideTitles = document.querySelectorAll(".slide-title");
    const slideContents = document.querySelectorAll(".banner");

    slideTitles.forEach((title, index) => {
      title.addEventListener("click", function () {
        // Remove active class from all titles
        slideTitles.forEach((t) => t.classList.remove("slide-title-active"));
        // Add active class to clicked title
        title.classList.add("slide-title-active");
        // Hide all contents
        slideContents.forEach((c) => c.classList.remove("active"));
        // Display corresponding content
        slideContents[index].classList.add("active");
      });
    });
  });
</script>
`}
              ></Script>
            </div>
          </div>
          <div>
            <div className="home-container06">
              <Script
                html={`<script defer>
const el = document.querySelector('.blaze-slider')

new BlazeSlider(el, {
  all: {
    slidesToShow: 3,
    slideGap: '80px',
    loop: true,
    enableAutoplay: true,
  },
})
</script>`}
              ></Script>
            </div>
          </div>
        </div>
      </section>
      <section className="home-get-started">
        <div className="home-header-container1">
          <div className="home-header09">
            <h2 className="home-heading06">
              <span>
                Transformez Votre Entreprise avec l&apos;IA :
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text17">
                Services de Développement Innovants et Automatisation des Tâches
              </span>
            </h2>
            <p className="home-caption14">
              REC assure la transformation digitale grâce à l&apos;intégration
              de l&apos;IA, incluant le développement de chatbots,
              d&apos;assistants vocaux IA, et d&apos;applications web dédiées
              aux services IA. Nous utilisons les dernières technologies du
              marché pour vous aider à automatiser vos tâches répétitives et à
              innover.
            </p>
          </div>
          <div className="home-button">
            <a
              href="https://tidycal.com/ai-rec/15-minute-meeting"
              target="_blank"
              rel="noreferrer noopener"
              className="home-button1 button"
            >
              <span>Commencez dès maintenant</span>
            </a>
          </div>
        </div>
        <img
          alt="image"
          sizes="(min-width: 768px) 800px, 480px"
          src="/getstarted-800w.webp"
          srcSet="/getstarted-800w.webp 800w, /getstarted-mobile.webp 480w"
          className="home-image05"
        />
      </section>
      <section className="home-objectives">
        <div className="home-content05">
          <span className="home-text19">Technologies</span>
          <div className="home-objectives-list">
            <div className="objective">
              <h3 className="home-text20">Low Code Framework</h3>
              <p className="home-text21">
                Gagnez du temps et optimisez votre budget de projet en utilisant
                le leader du marché des Frameworks Low Code, Bubble.io
              </p>
            </div>
            <div className="objective home-objective1">
              <h3 className="home-text22">IA et Automatisation</h3>
              <p className="home-text23">
                Boostez Votre Productivité avec l&apos;IA et
                l&apos;Automatisation avec Flowise, Langchain, n8n, et autre.
              </p>
            </div>
            <div className="objective home-objective2">
              <h3 className="home-text24">DevOps/LLMops</h3>
              <p className="home-text25">
                Optimisez Vos Opérations avec DevOps/LLMops avec Docker, Cloud
                Azure, Langsmith
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="s1" className="home-growth">
        <div className="home-content06">
          <div className="home-header10">
            <div className="home-header11">
              <h2 className="home-heading07">
                <span className="home-text26">Web App </span>
                <br className="home-text27"></br>
                <span className="home-text28">AI-Powered</span>
                <br className="home-text29"></br>
              </h2>
            </div>
            <p className="home-caption15">
              Dans le monde numérique en constante évolution
              d&apos;aujourd&apos;hui, les entreprises cherchent continuellement
              des stratégies pour accélérer le développement d&apos;applications
              web tout en garantissant l&apos;excellence.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
            <p className="home-caption16">
              Chez REC, nous avons non seulement relevé ce défi, mais nous avons
              également excellé en combinant la puissance des API d&apos;IA,
              l&apos;innovation sans code avec Bubble.io, et notre expertise en
              IA.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
          </div>
        </div>
        <div className="home-images">
          <div className="home-square"></div>
          <img
            alt="image"
            sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
            src="/ai-apps-1400w.webp"
            srcSet="/ai-apps-1400w.webp 1200w, /ai-apps-tablet.webp 800w, /ai-apps-mobile.webp 480w"
            className="home-image06"
          />
        </div>
      </section>
      <section id="s2" className="home-experience">
        <div className="home-images1">
          <div className="home-square1"></div>
          <video
            src="/pink%20and%20black%20animated%20science%20and%20technology%20mobile%20video.mp4"
            loop="true"
            muted="true"
            poster="https://play.teleporthq.io/static/svg/videoposter.svg"
            autoPlay="true"
            playsInline="true"
            className="home-video1"
          ></video>
        </div>
        <div className="home-content07">
          <div className="home-header12">
            <div className="home-header-container2">
              <div className="home-header13">
                <h2 className="home-heading08">
                  <span>
                    Votre IA
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <span className="home-text32">personnalisée</span>
                  <span> </span>
                </h2>
              </div>
              <p className="home-caption17">
                Solutions personnalisées adaptées à votre métier.
              </p>
            </div>
            <div className="home-checkmarks">
              <div className="home-check">
                <div className="home-mark">
                  <svg viewBox="0 0 1024 1024" className="home-icon21">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text34">
                  Chatbots personnalisés basés sur votre base documentaire.
                </span>
              </div>
              <div className="home-check1">
                <div className="home-mark1">
                  <svg viewBox="0 0 1024 1024" className="home-icon23">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text35">
                  AI voice assistance pour des interactions vocales fluides.
                </span>
              </div>
              <div className="home-check2">
                <div className="home-mark2">
                  <svg viewBox="0 0 1024 1024" className="home-icon25">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text36">
                  Automatisation des workflows avec vos applications et bases de
                  données.
                </span>
              </div>
              <div className="home-check3">
                <div className="home-mark3">
                  <svg viewBox="0 0 1024 1024" className="home-icon27">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text37">
                  Assistance complète pour garantir le succès de votre projet.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-data">
        <div className="home-header-container3">
          <div className="home-header14">
            <div className="home-row">
              <h2 className="home-heading09">
                <span>
                  Votre Solution IA de A à Z :
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="home-text39"></br>
                <span className="home-text40">
                  Conception, Déploiement et
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="home-text41"></br>
                <span className="home-text42">Support Complet</span>
              </h2>
            </div>
          </div>
          <p className="home-caption18">
            En suivant une approche DevOps et une approche LLMOps, nous nous
            assurons que votre solution IA est développée, déployée et maintenue
            de manière efficace et conforme aux normes
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </p>
        </div>
        <div className="home-content08">
          <div className="home-image07">
            <img
              alt="image"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/purple%20blue%20modern%20geometrical%20%20ai%20art%20instagram%20post%20%20(4)-1400w.webp"
              srcSet="/purple%20blue%20modern%20geometrical%20%20ai%20art%20instagram%20post%20%20(4)-1400w.webp 1200w, /purple%20blue%20modern%20geometrical%20%20ai%20art%20instagram%20post%20%20(4)-tablet.webp 800w, /purple%20blue%20modern%20geometrical%20%20ai%20art%20instagram%20post%20%20(4)-mobile.webp 480w"
              className="home-image08"
            />
          </div>
          <div className="home-accordion">
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header15">Analyse</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Analyse approfondie et compréhension de vos Besoins pour
                  cerner vos objectifs et défis
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header16">Architecture Adaptée</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Sélection des technologies appropriées, la conception de
                  workflows et la planification des intégrations avec vos
                  systèmes existants
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name1"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header17">Validation et Déploiement</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Validation du PoC, puis déploiement de la solution IA complète
                  selon les meilleures pratiques.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name2"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header18">Suivi et support</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Support continue et mises à jour régulières pour répondre à
                  l&apos;évolution de vos besoins et de la technologie.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name3"></IconContainer>
            </div>
            <div>
              <div className="home-container08">
                <Script
                  html={`<script>
 /*
  Accordion - Code Embed
  */
  const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
  const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
  const accordionIconsClosed = document.querySelectorAll('[data-role="accordion-icon-closed"]'); // All accordion closed icons
  const accordionIconsOpen = document.querySelectorAll('[data-role="accordion-icon-open"]'); // All accordion open icons

  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = "none"; //Hides all accordion contents
  });

  accordionIconsClosed.forEach((icon) => {
    icon.style.display = "flex"
  })

  accordionIconsOpen.forEach((icon) => {
    icon.style.display = "none"
  })

  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener("click", () => {
          if (accordionContents[index].style.display === "flex") {
              // If the accordion is already open, close it
              accordionContents[index].style.display = "none";
              accordionIconsClosed[index].style.display = "flex";
              accordionIconsOpen[index].style.display = "none"
          } else {
              // If the accordion is closed, open it
              accordionContents.forEach((accordionContent) => {
                  accordionContent.style.display = "none"; //Hides all accordion contents
              });

              accordionIconsClosed.forEach((accordionIcon) => {
                  accordionIcon.style.display = "flex"; // Resets all icon transforms to 0deg (default)
              });

              accordionIconsOpen.forEach((accordionIcon) => {
                accordionIcon.style.display = "none";
              })
              
              accordionContents[index].style.display = "flex"; // Shows accordion content
              accordionIconsClosed[index].style.display = "none"; // Rotates accordion icon 180deg
              accordionIconsOpen[index].style.display = "flex";
          }
      });
  });
</script>
`}
                ></Script>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-create">
        <div className="home-content17">
          <div className="home-header19">
            <h2 className="home-heading10">
              <span className="home-text43">
                Ensemble,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text44">
                concevons votre solution
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text45">I</span>
              <span className="home-text46">A</span>
              <span className="home-text47">!</span>
              <br className="home-text48"></br>
            </h2>
          </div>
          <a href="#contact-form-3-email" className="home-link07 button">
            <span>
              <span>Contactez-Nous</span>
              <br></br>
            </span>
          </a>
        </div>
      </section>
      <section id="qui sommesnous" className="home-customer">
        <div className="home-header20">
          <h2 className="home-heading11">
            <span className="home-text52">Qui Sommes-Nous ?</span>
            <br></br>
          </h2>
          <p className="home-caption19">
            <span>
              REC &quot;Refection Engineering Consulting&quot;, est une
              entreprise basée en Tunisie opérant à l&apos;international en tant
              que société offshore.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>
              Fondée par l&apos;ingénieure Yasmine Boudhina, forte de plus de 10
              ans d&apos;expérience dans l&apos;innovation, de la conception à
              la mise en œuvre dans divers domaines.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>
              REC est votre partenaire idéal pour une intégration transparente
              de l&apos;IA, que vous soyez une petite entreprise, une startup ou
              une grande entreprise.
            </span>
          </p>
        </div>
        <Testimonial13></Testimonial13>
        <ContactForm3></ContactForm3>
      </section>
      <div>
        <div className="home-container10">
          <Script
            html={`<script type="module">
    import Chatbot from "https://cdn.jsdelivr.net/npm/flowise-embed/dist/web.js"
    Chatbot.init({
        chatflowid: "38340dae-5db2-4daf-89c9-2514f9196691",
        apiHost: "https://flowise.rec-it.net",
        chatflowConfig: {
            // topK: 2
        },
        theme: 
        {
            "button": {
                "backgroundColor": "#451457",
                "right": "100",
                "bottom": "40",
                "size": "large",
                "iconColor": "white",
                "customIconSrc": "https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg"
            },
            "chatWindow": {
                "welcomeMessage": "Bonjour ! Je suis l'assistant commercial de REC. Je suis là pour vous aider à trouver les meilleures solutions pour vos besoins en intelligence artificielle.",
                "backgroundColor": "#e8e8e8",
                "height": 700,
                "width": 400,
                "fontSize": 16,
                "poweredByTextColor": "#e8e8e8",
                "botMessage": {
                "backgroundColor": "#f5f5f5",
                "textColor": "#303235",
                "showAvatar": true,
                "avatarSrc": "https://static-00.iconduck.com/assets.00/assistant-icon-1901x2048-s12i4saj.png"
                },
                "userMessage": {
                "backgroundColor": "#3B81F6",
                "textColor": "#ffffff",
                "showAvatar": true,
                "avatarSrc": "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png"
                },
                "textInput": {
                "placeholder": "Votre Question",
                "backgroundColor": "#ffffff",
                "textColor": "#303235",
                "sendButtonColor": "#3B81F6"
                }
            }
        }
    })
</script>
`}
          ></Script>
        </div>
      </div>
      <Gallery5></Gallery5>
      <section className="home-faq">
        <div className="home-header21">
          <h2 className="home-heading12">Questions Fréquentes</h2>
        </div>
        <div className="home-content18">
          <div className="home-column">
            <div className="home-element4">
              <h3 className="home-header22">
                Quels sont les avantages de l&apos;utilisation de l&apos;IA dans
                les entreprises ?
              </h3>
              <p className="home-content19">
                L&apos;IA offre plusieurs avantages, notamment une
                automatisation accrue des tâches, une amélioration de la prise
                de décision basée sur les données, et une meilleure expérience
                client grâce à des interactions plus personnalisées.
              </p>
            </div>
            <div className="home-element5">
              <h3 className="home-header23">
                Est-ce que le chatbot vocal AI est capable de prendre des
                commandes et d&apos;effectuer des actions comme des réservations
                ?
              </h3>
              <p className="home-content20">
                Oui, les chatbots vocaux AI sont capables de prendre des
                commandes et d&apos;effectuer des actions telles que des
                réservations. Ils peuvent interpréter les commandes vocales, les
                traiter et effectuer des actions en conséquence, offrant ainsi
                une expérience utilisateur pratique et efficace.
              </p>
            </div>
            <div className="home-element6">
              <h3 className="home-header24">
                Mon chatbot peut-il extraire des informations de ma base de
                données SQL ou de mon CRM pour fournir des détails sur une
                commande, par exemple ?
              </h3>
              <p className="home-content21">
                Oui, votre chatbot peut interagir avec votre base de données SQL
                ou votre CRM pour extraire des informations spécifiques, comme
                des détails de commande. Avec n8n, qui propose plus de 400
                intégrations, vous pouvez facilement personnaliser ces
                interactions en utilisant des APIs pour répondre à vos besoins
                précis.
              </p>
            </div>
          </div>
          <div className="home-column1">
            <div className="home-element7">
              <h3 className="home-header25">
                Comment les chatbots peuvent-ils améliorer l&apos;efficacité de
                mon service client ?
              </h3>
              <p className="home-content22">
                Les chatbots peuvent répondre instantanément aux questions
                fréquemment posées, ce qui réduit le temps d&apos;attente des
                clients et libère les agents pour des tâches plus complexes. Ils
                peuvent également fonctionner 24h/24, 7j/7, offrant ainsi un
                support continu.
              </p>
            </div>
            <div className="home-element8">
              <h3 className="home-header26">
                Comment puis-je intégrer un chatbot à mon site web ?
              </h3>
              <p className="home-content23">
                Nous vous proposons une intégration simple et sans modification
                de votre site web. Il vous suffit d&apos;intégrer le code
                d&apos;insertion (embed code) de notre chatbot préalablement
                réalisé dans le code source de votre site. Cette méthode vous
                permet d&apos;ajouter rapidement et facilement un chatbot à
                votre site web, sans avoir à effectuer de modifications
                importantes.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="home-footer">
        <div className="home-content24">
          <div className="home-main">
            <div className="home-branding1">
              <img
                alt="image"
                src="/untitled%20design%20(1).svg"
                className="home-image09"
              />
              <span className="home-text59">
                <span>
                  Vous offre une technologie IA de pointe pour distancer la
                  concurrence.
                </span>
                <br></br>
                <br></br>
                <span>Contact@rec-it.net</span>
                <br></br>
              </span>
            </div>
            <div className="home-links">
              <div className="home-column2">
                <span className="home-header27">Menu</span>
                <div className="home-list">
                  <a href="#HOME" className="home-link08">
                    Accueil
                  </a>
                  <a href="#Services" className="home-link09">
                    Services
                  </a>
                  <a href="#s1" className="home-link10">
                    Solutions
                  </a>
                  <a href="#contact-form-3-email" className="home-link11">
                    <span className="home-text65">Contactez-Nous</span>
                    <br></br>
                  </a>
                </div>
              </div>
              <div className="home-column3">
                <span className="home-header28">Social</span>
                <div className="home-list1">
                  <a
                    href="https://www.linkedin.com/company/100189764/admin/feed/posts/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link12"
                  >
                    LinkedIn
                  </a>
                  <a
                    href="https://medium.com/@rec-it.net"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link13"
                  >
                    Medium
                  </a>
                  <a
                    href="https://www.youtube.com/@rec-yas"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link14"
                  >
                    Youtube
                  </a>
                  <a
                    href="https://www.tiktok.com/@rec.international"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link15"
                  >
                    TikTok
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="home-bottom">
            <span className="home-text67">
              © 2024 REC - All rights reserved
            </span>
            <button data-role="scroll-top" className="home-button2 button">
              <img alt="image" src="/arrow.svg" className="home-image10" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="home-container12">
          <Script
            html={`<script>
document.addEventListener("DOMContentLoaded", function() {
  // get the element with the "scroll-top" data-role
  const scrollTopBtn = document.querySelector('[data-role="scroll-top"]');

  // when the element is clicked
  scrollTopBtn.addEventListener("click", function() {
    // smoothly scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
});
</script>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

export default Home
