import React from 'react'

import PropTypes from 'prop-types'

import './gallery5.css'

const Gallery5 = (props) => {
  return (
    <div id="media" className="gallery5-gallery3 thq-section-padding">
      <div className="gallery5-max-width thq-section-max-width">
        <div className="gallery5-section-title">
          <h2 className="gallery5-text">{props.heading1}</h2>
          <span className="gallery5-text1">{props.content1}</span>
        </div>
        <div className="gallery5-container">
          <div className="gallery5-content">
            <div className="gallery5-container1">
              <div className="gallery5-container2">
                <a
                  href="https://www.youtube.com/playlist?list=PLrfcWevbUpiltvrHCVaOMo1-jeP4fho_B"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="gallery5-link"
                >
                  <img
                    alt={props.image1Alt}
                    src={props.image1Src}
                    className="gallery5-image1 thq-img-ratio-4-3"
                  />
                </a>
              </div>
              <a
                href="https://www.youtube.com/playlist?list=PLrfcWevbUpin2u5WFkeA3-8-oVPyNdsYL"
                target="_blank"
                rel="noreferrer noopener"
                className="gallery5-link1"
              >
                <img
                  alt={props.image2Alt}
                  src={props.image2Src}
                  className="gallery5-image2 thq-img-ratio-1-1"
                />
              </a>
            </div>
            <div className="gallery5-container3">
              <a
                href="https://www.youtube.com/playlist?list=PLrfcWevbUpiljdC3JPFLyP_4vBid4Ersf"
                target="_blank"
                rel="noreferrer noopener"
                className="gallery5-link2"
              >
                <img
                  alt={props.image3Alt}
                  src={props.image3Src}
                  className="gallery5-image3 thq-img-ratio-1-1"
                />
              </a>
              <a
                href="https://www.youtube.com/playlist?list=PLrfcWevbUpiljdC3JPFLyP_4vBid4Ersf"
                target="_blank"
                rel="noreferrer noopener"
                className="gallery5-link3"
              >
                <img
                  alt={props.image4Alt}
                  src={props.image4Src}
                  className="gallery5-image4 thq-img-ratio-1-1"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery5.defaultProps = {
  image3Src: '/14-1400w.webp',
  image2Src: '/12-1400w.webp',
  image1Alt: 'Personalized AI Solutions',
  content1:
    "Découvrez le pouvoir de l'innovation et de la réflexion avec REC, votre partenaire ultime pour stimuler la transformation numérique ! Plongez-vous dans le monde de l'intelligence artificielle, du développement professionnel, de DevOps et de l'infrastructure cloud avec nos playlists captivantes.",
  image1Src: '/13-1400w.webp',
  image4Src: '/15-1400w.webp',
  image3Alt: 'DevOps and LLMOps Technologies',
  heading1: 'REC Playlists',
  image4Alt: 'Web Application Development',
  image2Alt: 'Intelligent Chatbots',
}

Gallery5.propTypes = {
  image3Src: PropTypes.string,
  image2Src: PropTypes.string,
  image1Alt: PropTypes.string,
  content1: PropTypes.string,
  image1Src: PropTypes.string,
  image4Src: PropTypes.string,
  image3Alt: PropTypes.string,
  heading1: PropTypes.string,
  image4Alt: PropTypes.string,
  image2Alt: PropTypes.string,
}

export default Gallery5
